<script setup>
import texts from "~/configs/texts";

const siteConfig = useSiteConfig();
const contact = useContact();

const props = defineProps({
  visible: Boolean,
  contactPhone: String,
  default: () => ''
})

const emits = defineEmits(['dismiss'])

const showBox = ref(false);
const animateIn = ref(false);
const refStateBlend = ref();

const defaultData = {
  phone: "",
  agr_p: false,
}
const formData = ref({...defaultData});
const errors = ref({...defaultData});

const phoneExpand = ref(false);

const clearForm = () => {
  formData.value = {...defaultData};
  errors.value = {...defaultData};
}

const validateAndSend = async () => {
  errors.value = {...defaultData}
  let passed = true;

  if (!formData.value.phone || !validatePhone(formData.value.phone)) {
    errors.value.phone = "Wprowadź poprawny numer telefonu";
    passed = false;
  }

  if (!formData.value.agr_p) {
    errors.value.agr_p = "To pole jest wymagane";
    passed = false;
  }

  if(passed) {
    refStateBlend.value.setBusy();
    formData.value.formType = 'box';
    const result = await contact.submit(formData)
    if(!result) {
      refStateBlend.value.setFailure();
    } else {
      refStateBlend.value.setSuccess();
      clearForm();
    }
  }
}

watch(() => props.visible,
  (isVisible) => {
    if (isVisible) {
      showBox.value = isVisible;
      setTimeout(() => animateIn.value = isVisible, 100);
    } else {
      animateIn.value = isVisible;
      setTimeout(() => showBox.value = isVisible, 100);
    }
  }
)

</script>

<template>
  <div class="fixed left-0 right-0 h-screen flex items-start justify-center z-70 transition-all -top-[100vh] pointer-events-none ease-in-out"
       :class="{'hidden':!showBox, '!top-0':animateIn}">
    <div class="relative rounded bg-white shadow-box w-[280px] z-60 mt-20 pointer-events-auto overflow-hidden">
      <FormStateBlend ref="refStateBlend" />
      <div class="p-4 flex flex-col items-center">
        <div class="absolute right-2 top-2 cursor-pointer" @click="emits('dismiss')">
          <IconClose />
        </div>
        <div v-if="isInWorkingHours()" class="font-normal text-xl">Zadzwoń teraz</div>

        <div v-else class="text-center font-light pt-6">
          <div>Pracujemy w godz. 9:00 - 17:00</div>
          <div>od poniedziałku do piątku</div>
        </div>
        <div v-if="isInWorkingHours()" class="rounded-full bg-white shadow-box w-24 h-24 flex justify-center items-center my-4">
          <a :href="'tel:' + props.contactPhone">
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.3794 3.72439L14.3794 3.72468L16.8954 18.8037L16.8955 18.8039C16.998 19.4165 16.9012 20.0457 16.6193 20.5991C16.3374 21.1525 15.8852 21.6008 15.3295 21.8779L15.3292 21.8781L10.066 24.5063L9.65294 24.7125L9.82572 25.1407C11.7382 29.8802 14.5865 34.1856 18.2005 37.7995C21.8144 41.4134 26.1198 44.2617 30.8593 46.1743L31.2872 46.3469L31.4936 45.9342L34.1252 40.671L34.1254 40.6706C34.4024 40.1154 34.8502 39.6636 35.403 39.3817C35.9557 39.0999 36.5843 39.0028 37.1963 39.1046C37.1964 39.1046 37.1964 39.1046 37.1965 39.1046L52.2753 41.6206L52.2756 41.6206C52.9527 41.7332 53.568 42.0823 54.0119 42.6059C54.4558 43.1294 54.6996 43.7934 54.7 44.4798C54.7 44.4799 54.7 44.4799 54.7 44.48V51.8C54.7 52.5691 54.3945 53.3067 53.8506 53.8506C53.3067 54.3945 52.5691 54.7 51.8 54.7H45C20.8641 54.7 1.29999 35.1358 1.29999 11V4.19999C1.29999 3.43086 1.60552 2.69323 2.14938 2.14938C2.69323 1.60552 3.43086 1.29999 4.19999 1.29999H11.5201C11.5201 1.29999 11.5202 1.29999 11.5202 1.29999C12.2066 1.30036 12.8705 1.54416 13.3941 1.98804L13.7174 1.60667L13.3941 1.98804C13.9176 2.43197 14.2668 3.04723 14.3794 3.72439Z" fill="#699C64" stroke="#699C64"></path>
            </svg>
          </a>
        </div>
        <div v-else class="rounded-full bg-gray-100 w-12 h-12 flex justify-center items-center my-4">
          <svg aria-hidden="true" class="w-6 h-6" fill="#9CA3AF" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
          </svg>
        </div>
        <div v-if="isInWorkingHours()" class="text-center text-sm">
          <div>lub zostaw numer telefonu,</div>
          <div>zadzwonimy do Ciebie:</div>
        </div>
        <div v-else class="text-center text-sm font-extralight">
          <div>Zostaw numer telefonu,</div>
          <div>zadzwonimy do Ciebie w najbliższym dniu roboczym!</div>
        </div>
        <div class="w-full my-6">
          <InputText label="Numer telefonu" type="tel" v-model="formData.phone" :error="errors.phone" />
        </div>
        <div>
          <InputCheckBox @change="(checked) => formData.agr_p = checked" :error="errors.agr_p">
            <span class="pr-1">{{ texts.form.agreements.phone.small }}</span>
            <ButtonToggle @toggle="(e) => phoneExpand = e">
              <template v-slot:default>{{ texts.form.agreements.phone.more }}</template>
              <template v-slot:toggled>{{ texts.form.agreements.phone.less }}</template>
            </ButtonToggle>
            <Accordion :expanded="phoneExpand">
              {{ texts.form.agreements.phone.large }}
            </Accordion>
          </InputCheckBox>
        </div>
        <div class="w-full mt-6">
          <ButtonRounded color="azure" @click="validateAndSend">Zamawiam kontakt</ButtonRounded>
        </div>
      </div>
    </div>
  </div>
</template>
